import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

import MessengerCustomerChat from 'react-messenger-customer-chat';


export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {

    return (
      <>
        < Layout >
          <SEO title="Home" />
          <section className="page-section bg-primary" id="about">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                  <h2 className="text-white mt-0">Depuis 25 ans ! 7/7 de 8h à 19h.<br />Tout ce qu'il faut pour construire et rénover</h2>
                  <hr className="divider light my-4" />
                  <p className="text-white-50 mb-4">Que vous soyez experts ou amateurs<br /> vous trouverez toujours votre bonheur chez Brico Barrière.</p>
                  <a className="btn btn-light btn-xl js-scroll-trigger" href="#services"
                    onClick={Scroller.handleAnchorScroll} title="Brico barriere - Vente de tous les materiaux de construction et de gros œuvre ainsi que tout ce qui concerne l'électricité,sanitaire peinture, quincaillerie, outillage,toiture, vêtements de travail, bricolage, isolation, et aussi les outils de jardinage">Nos Services</a>
                </div>
              </div>
            </div>
          </section>

          <section className="page-section" id="services">
            <div className="container">
              <h2 className="text-center mt-0">À votre service</h2>
              <h3>Saviez vous que Brico Barrière ouvre le dimanche de 8:30h à 18h pour permettre aux personnes qui travaillent la semaine de faire leur travaux et bricolage en toute tranquillité</h3>
              <hr className="divider my-4" />
              <div className="row">
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <i className="fas fa-4x fa-shipping-fast text-primary mb-4" title="Livraison rapide et partout en Belgique"></i>
                    <h3 className="h4 mb-2">Livraison rapide et partout en Belgique</h3>
                    <p className="text-muted mb-0"></p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <i className="fas fa-4x fa-credit-card text-primary mb-4" title="Payement à la livraison"></i>
                    <h3 className="h4 mb-2">Payement à la livraison</h3>
                    <p className="text-muted mb-0"></p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <i className="fas fa-4x fa-parking text-primary mb-4" title="Parking sur place"></i>
                    <h3 className="h4 mb-2">Parking</h3>
                    <p className="text-muted mb-0"></p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <i className="fas fa-4x fa-users text-primary mb-4" title="Personnel qualifié pour vous conseiller dans le domaine de la construction, du gros-oeuvre, la rénovation, du sanitaire, de l'électricité, du bricolage et du jardinage"></i>
                    <h3 className="h4 mb-2">Personnelle qualifié</h3>
                    <p className="text-muted mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="portfolio">
            <div className="container-fluid p-0">
              <div className="row no-gutters">
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="img/portfolio/cat/1.jpg" onClick={this.handlePortfolioClick.bind(this, 0)} title="Matériau de construction, Batiment - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[0].node.childImageSharp.fluid} title="Matériau de construction, Batiment - Brico Barrière" alt="Matériau de construction - Brico Barrière" />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        Construction
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="img/portfolio/cat/2.jpg" onClick={this.handlePortfolioClick.bind(this, 1)} title="Outillage: outil de construction et outil de jardinage - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[1].node.childImageSharp.fluid} title="Outillage: outil de construction et outil de jardinage - Brico Barrière" alt="outil de construction et outil de jardinage - Brico Barrière" />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        Outillage
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="img/portfolio/cat/3.jpg" onClick={this.handlePortfolioClick.bind(this, 2)} title="peinture interieur et exterieur - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[2].node.childImageSharp.fluid} title="peinture interieur et exterieur - Brico Barrière" alt="peinture interieur et exterieur - Brico Barrière" />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        Peinture
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="images/portfolio/cat/4.jpg" onClick={this.handlePortfolioClick.bind(this, 3)} title="sanitaire, chauffage, pvc sanitaire et salle de bain - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[3].node.childImageSharp.fluid} title="sanitaire, chauffage, pvc sanitaire et salle de bain - Brico Barrière" alt="sanitaire,chauffage, pvc sanitaire et salle de bain - Brico Barrière" />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        salle de bains
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="img/portfolio/cat/5.jpg" onClick={this.handlePortfolioClick.bind(this, 4)} title="Luminaire et électricité domestique et industrielle - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[4].node.childImageSharp.fluid} title="Luminaire et électricité domestique et industrielle - Brico Barrière" alt="Luminaire et électricité domestique et industrielle - Brico Barrière" />
                    <div className="portfolio-box-caption">
                      <div className="project-category text-white-50">
                        Luminaire
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <a className="portfolio-box" href="img/portfolio/cat/6.jpg" onClick={this.handlePortfolioClick.bind(this, 5)} title="Outil de jardinage - Brico Barrière">
                    <Img fluid={this.props.data.images.edges[5].node.childImageSharp.fluid} title="Outil de jardinage - Brico Barrière" alt="Outil de jardinage - Brico Barrière" />
                    <div className="portfolio-box-caption p-3">
                      <div className="project-category text-white-50">
                        Jardinage
                    </div>
                      <div className="project-name">

                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="page-section">
            <div className="container text-center">
              <h2 className="mb-4">Nous collaborons avec</h2>
              <div className="brands-container">
                <Img className="brand" fluid={this.props.data.brands.edges[0].node.childImageSharp.fluid} alt="La marque HG - Brico Barriere" title="La marque HG - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[1].node.childImageSharp.fluid} alt="La marque Legrand - Brico Barriere" title="La marque Legrand - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[2].node.childImageSharp.fluid} alt="La marque 3M - Brico Barriere" title="La marque 3M - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[14].node.childImageSharp.fluid} alt="La marque Vaillant - Brico Barriere" title="La marque Vaillant - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[3].node.childImageSharp.fluid} alt="La marque Allibert - Brico Barriere" title="La marque Allibert - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[11].node.childImageSharp.fluid} alt="La marque Bosch - Brico Barriere" title="La marque Bosch - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[4].node.childImageSharp.fluid} alt="La marque Geberit - Brico Barriere" title="La marque Geberit - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[5].node.childImageSharp.fluid} alt="La marque Grohe - Brico Barriere" title="La marque Grohe - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[6].node.childImageSharp.fluid} alt="La marque Knipexe - Brico Barriere" title="La marque Knipexe - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[9].node.childImageSharp.fluid} alt="La marque Schneider - Brico Barriere" title="La marque Schneider - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[10].node.childImageSharp.fluid} alt="La marque Stanley - Brico Barriere" title="La marque Stanley - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[12].node.childImageSharp.fluid} alt="La marque Barbantia - Brico Barriere" title="La marque Barbantia - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[13].node.childImageSharp.fluid} alt="La marque Levis peinture - Brico Barriere" title="La marque Levis peinture - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[7].node.childImageSharp.fluid} alt="La marque Kopp - Brico Barriere" title="La marque Kopp - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[15].node.childImageSharp.fluid} alt="La marque Vynckier - Brico Barriere" title="La marque Vynckier - Brico Barriere" />
                <Img className="brand" fluid={this.props.data.brands.edges[16].node.childImageSharp.fluid} alt="La marque Van Marcke - Brico Barriere" title="La marque Van Marcke - Brico Barriere" />
              </div>
              <br />
              <br />
              <h4>Que vous soyez experts ou amateurs, vous trouverez toujours votre bonheur chez Brico Barrière.</h4>
              <p>
                Notre magasin vous accompagnera dans toutes les étapes de votre projet, du gros œuvre aux travaux de sanitaires, carrelage, plomberie, électricité, toiture et même le jardinage.
              </p>
            </div>
          </section>

          <section className="page-section bg-dark text-white" id="contact">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                  <h2 className="mt-0">Contactez-nous !</h2>
                  <hr className="divider my-4" />
                  <p className="text-muted mb-5">Pour commander ou pour toute question ou demande d'information, n'hésitez pas à nous contacter.</p>
                  <p className="text-muted mb-5">Notre équipe se fera un plaisir de vous aider pour toutes vos questions concernant vos projets de construction, rénovation, bricolage ou jardinage.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                  <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                  <a className="d-block" href="tel:003225348797" title="Pour toute question ou information n'hésitez pas à nous contacter. Notre équipe se fera un plaisir de vous aider pour toutes vos questions concernant vos projets de construction, rénovation, bricolage ou jardinage.">(+32) 02 534 87 97</a>
                </div>
                <div className="col-lg-4 mr-auto text-center">
                  <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                  <a className="d-block" href="mailto:info@bricobarriere.be" title="Pour toute question ou information n'hésitez pas à nous contacter. Notre équipe se fera un plaisir de vous aider pour toutes vos questions concernant vos projets de construction, rénovation, bricolage ou jardinage.">info@bricobarriere.be</a>
                </div>
              </div>
              <br /><br />
              <div className="row">
                <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0 info">
                  <i className="fas fa-map-marker-alt fa-3x mb-3 text-muted"></i>
                  <ul>
                    <li>Rue Théodore Verhaegen 64,</li>
                    <li>1060 Saint-Gilles</li>
                    <li>Bruxelles – Belgique</li>
                    <li>Tram 81: Guillaume Tell <br /><br /> Cliquez <a href="https://www.stib-mivb.be/horaires-dienstregeling2.html?l=fr&_line=81&_directioncode=V" target="_blank" rel="nofollow" title="Voir les horaires">ici</a> pour voir les horaires.</li>
                  </ul>
                </div>
                <div className="col-lg-4 mr-auto text-center info">
                  <i className="fas fa-clock fa-3x mb-3 text-muted"></i>
                  <ul>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lundi: 7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mardi : 7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mercredi:7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jeudi: 7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vendredi: 7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Samedi: 7:30 – 19:00</li>
                    <li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dimanche: 8:30 – 18:00</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <PortfolioModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
            <PortfolioCarousel images={this.props.data.images.edges} current={this.state.modalCurrent} />
          </PortfolioModal>
        </Layout >
        <MessengerCustomerChat
          pageId="1243906702363009"
          appId="766304570614564"
          title="Facebook messenger"
          rel="preconnect"
        />
      </>
    )
  }
}


export const imageData = graphql`
  query {
              images: allFile(filter: {relativePath: {glob: "portfolio/cat/*.jpg"}}, sort: {fields: name}) {
              edges {
              node {
              childImageSharp {
              fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    brands: allFile(filter: {relativePath: {glob: "brands/*.png"}}, sort: {fields: name}) {
              edges {
              node {
              childImageSharp {
              fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    
  }
`
